import TimeCard from "./timecard";
import { useState } from "react";

const Expsec = () => {

    const [open, setOpen] = useState("");
    return (
        <>
        <h4 className="heading">recently i have been  :- </h4>
        <section className="section">
        

        <TimeCard
            heading="backend @GoQuant"
            time="feb25 - present"
            duration="ongoing"
            description="Working as a backend engineer intern on systems, performance optimization and development"
            link="#"
            id="8"
            openId={open}
            setOpenid={setOpen}
          />
        
        <TimeCard
            heading="applied research @IIT-M"
            time="jul24 - sep24"
            duration="ongoing"
            description="research intern IIT Madras working on blockchain and hyperledger fabric . Developed Solidity smart contracts and integrated Hyperledger Fabric for secure,automated blockchain transactions
and asset backing."
            link="#"
            id="0"
            openId={open}
            setOpenid={setOpen}
          />
        <TimeCard
            heading="backend engineer"
            time="may24 - aug24"
            duration="3mo"
            description="Worked on a YouTube SaaS platform, providing creators with detailed analytics and AI tooling. 
            Engineered backend workflows using Node.js, YouTube APIs, Redis and FastApi improving LLM response times by 80%. Designed backend flows across 10+ features to integrate with LLMs, automating video insights and recommendations, driving user engagement and revenue growth."
            link="#"
            id="1"
            openId={open}
            setOpenid={setOpen}
          />
          <TimeCard
            heading="fullstack intern"
            time="jan24 - feb24"
            duration="1mo"
            description="Development of the company website in Nextjs. Implemented forms, validation and client database on the website's frontend and backend."
            link="#"
            id="2"
            openId={open}
            setOpenid={setOpen}
          />
          <TimeCard
            heading="backend intern "
            time="nov23 - dec23"
            duration="2mo"
            description="Fixed API calls to connect wallet to Dapp for a cross chain liquidity pool. Deployed the website on AWS EC2 with reverse proxy and process manager setup"
            link="#"
            id="3"
            openId={open}
            setOpenid={setOpen}
          />
          <TimeCard
            heading="tech coordinator"
            time="nov23 - feb24"
            duration="4mo"
            description="Contributed to 4+ open source projects. Lead a team of 15 developers for Makeathon 6 website, attracting 2000+ visitors"
            link="#"
            id="5"
            openId={open}
            setOpenid={setOpen}
          />
            <hr style={{width:'15%', opacity:'0.6'}}  />
            <TimeCard
            heading="winner @ISRO hackathon"
            time="aug24"
            duration="1mo"
            description="Our team won the nationwide 1st in the ISRO (Indian space research organization) hackathon. We were awarded by the hon'ble President of India for our project."
            link="#"
            id="23"
            openId={open}
            setOpenid={setOpen}
          />
           <TimeCard
            heading="research"
            time="ongoing"
            duration="ongoing"
            link="#"
            description = "I am currently reading up on Interaction combinators and working on exploring a more secure model of https for post quantumn. I am pursuing this as a part of personal research interests"
            id="24"
            openId={open}
            setOpenid={setOpen}
          />
           {/* <TimeCard
            heading="founder, mint clothing"
            time="aug23 - now"
            duration="ongoing"
            description="A little side hustle xD , i run a small clothing brand. Had to feed my dream of owning a clothing brand"
            link="#"
            id="5"
            openId={open}
            setOpenid={setOpen}
          /> */}
        </section>
        </>
    )   
}

export default Expsec;