import React from 'react';
import Navbar from "../components/navbar";
import "./LinkTreePage.css";
import SocialTab from '../components/socialtab';

const LinktreePage = () => {
  // Hardcoded links
  const links = [
    { 
      id: 1, 
      title: 'Implementation Walkthrough Video', 
      url: 'https://drive.google.com/file/d/1yezKy0PCtiTlOpvt8wMmlnAEauTpnU-t/view?usp=sharing' 
    },
    { 
      id: 2, 
      title: 'Technical Overview Video', 
      url: 'https://drive.google.com/file/d/1AvIXoEGHy9A0NGbqmPsNBz2kUlXEN2Ka/view?usp=sharing' 
    },
    { 
      id: 3, 
      title: 'Design Document', 
      url: 'https://drive.google.com/file/d/1UjYTAWtxSG6FU6RQ9b0NxYbwajqdLNOY/view?usp=sharing' 
    },{
        id: 4,
        title: 'Documentation',
        url: "https://drive.google.com/file/d/12Z513pRyAt5hcKmkVoKw_vwr6QCKHy6q/view?usp=sharing"
    }

  ];

  return (
    <div className="App">
      <div className="parent">
        <Navbar />
        
        <div className="linktree-container">
          <div className="linktree-header">
            <h1>FIX Trading Engine</h1>
            <p className="bio">A comprehensive FIX Protocol implementation from scratch</p>
          </div>

          <div className="links-container">
            {links.map(link => (
              <a 
                key={link.id}
                href={link.url}
                target="_blank"
                rel="noopener noreferrer"
                className="link-box"
              >
                {link.title}
              </a>
            ))}
          </div>
          
          <div className="project-description">
            <p>This project includes:</p>
            <ul>
              <li>Basic FIX Engine with connection, heartbeat, and sequence number management</li>
              <li>Multi-threaded architecture with lock-free queues to minimize contention</li>
              <li>Full order lifecycle management (place, modify, cancel)</li>
              <li>Real-time market data streaming via WebSocket</li>
              <li>Multi-client support with subscription management</li>
              <li>Support for all major instrument types (Spot, Futures, Options)</li>
            </ul>
          </div>
          
         
          
          <div className="linktree-footer">
            <p>© 2025 Aditya Sapra</p>
            <SocialTab />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LinktreePage;